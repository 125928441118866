import { browser } from '$app/environment';
import { derived, get, readonly, writable } from 'svelte/store';

enum CONSENT_ACTION {
	ACCEPT_ALL_SERVICES = 'onAcceptAllServices',
	DENY_ALL_SERVICES = 'onDenyAllServices',
	ESSENTIAL_CHANGE = 'onEssentialChange',
	INITIAL_PAGE_LOAD = 'onInitialPageLoad',
	MOBILE_SESSION_RESTORED = 'onMobileSessionRestore',
	NON_EU_REGION = 'onNonEURegion',
	SESSION_RESTORED = 'onSessionRestored',
	UPDATE_SERVICES = 'onUpdateServices'
}

enum CONSENT_TYPE {
	EXPLICIT = 'explicit',
	IMPLICIT = 'implicit'
}

interface ConsentHistory {
	action: CONSENT_ACTION;
	language: string;
	status: boolean;
	timestamp: number;
	type: CONSENT_TYPE;
	versions: { application: string; service: string; settings: string };
}

interface Consent {
	history: Array<ConsentHistory>;
	status: boolean;
}

interface Disclosure {
	cookieRefresh: null | boolean;
	description: null | string;
	domain: null | string;
	identifier: null | string;
	maxAgeSeconds: null | number;
	name: null | string;
	purposes: null | number[];
	type: string;
}

interface DeviceStorage {
	disclosures: Array<Disclosure>;
}

export interface BaseService {
	categorySlug: string;
	consent: Consent;
	description: string;
	deviceStorage: null | DeviceStorage;
	id: string;
	isEssential: boolean;
	isHidden: boolean;
	legalBasis: Array<string>;
	name: string;
	processorId: string;
	subServices: Array<any>;
	subServicesLength: number;
	version: string;
}

const createUserCentricsHelper = () => {
	const isListening = writable(false);
	const serviceData = writable<BaseService[]>([]);
	const hasChosen = derived(serviceData, ($serviceData) => $serviceData.length > 0);

	const updateConsentStatus = () => {
		console.log('updateConsentStatus');
		const newData = (window as any).UC_UI?.getServicesBaseInfo() as BaseService[];
		if (!newData || newData.length === 0) {
			return;
		}
		serviceData.set(newData);
	};

	const init = () => {
		if (get(isListening) || !browser) {
			return;
		}
		isListening.set(true);
		window.addEventListener('UC_UI_INITIALIZED', () => {
			updateConsentStatus();
		});
		window.addEventListener('UC_UI_CMP_EVENT', () => {
			updateConsentStatus();
		});
	};

	const hasConsent = (name: string) => {
		if (!browser) {
			const tmp = writable(false);
			return readonly(tmp);
		}
		init();
		return derived(serviceData, ($serviceData) =>
			Boolean($serviceData.find((service) => service.name === name)?.consent?.status ?? false)
		);
	};

	return {
		isListening: readonly(isListening),
		hasConsent,
		hasChosen
	};
};

export const userCentricsHelper = createUserCentricsHelper();
