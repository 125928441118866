<script lang="ts">
	import { userCentricsHelper } from '$lib/store/userCentricsHelper.store.js';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { createBreakpointStore } from '$lib/store/breakpoint.store';
	import { dev } from '$app/environment';
	import { page } from '$app/stores';
	import { onMount } from 'svelte';

	export let video_id: string;
	export let autoplay: boolean | undefined;
	export let className: string | undefined;

	export { className as class };

	const youtubeCookiesAccepted = userCentricsHelper.hasConsent('YouTube Video');
	const mobileStore = createBreakpointStore();
	$: showInstant = $page.route.id !== '/[locale]-[country]/[branch=branch]/[slug]/[handle]';
	let afterLoad = false;
	$: isMobile = $mobileStore;

	onMount(() => {
		setTimeout(() => {
			afterLoad = true;
		}, 1000);
	});
</script>

{#if ($youtubeCookiesAccepted || dev) && (showInstant || afterLoad)}
	<div class={classes('h-full w-full', className)}>
		<iframe
			src={`https://www.youtube-nocookie.com/embed/${video_id}?${isMobile ? '' : 'controls=1&'}${
				autoplay ? 'autoplay=1&' : ''
			}mute=1&rel=0&loop=1&playlist=${video_id}`}
			title="YouTube video"
			frameborder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowfullscreen
			class="w-full h-full"
		/>
	</div>
{/if}
